/* Estilo do fundo do modal */
.modal.fade.show {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro */
    z-index: 1050; /* Para garantir que o modal fique sobre outros elementos */
  }
  
  /* Estilo da caixa do modal */
  .modal-dialog {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 10% auto; /* Centraliza o modal verticalmente */
  }
  
  /* Estilo do conteúdo do modal */
  .modal-content {
    background-color: #fff;
    border-radius: 5px;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Estilo do cabeçalho do modal */
  .modal-header {
    padding: 10px 15px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #ddd;
  }
  
  .modal-title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  /* Estilo do botão de fechar */
  .modal-header .close {
    font-size: 1.5rem;
    color: #000;
    border: none;
    background: transparent;
  }
  
  /* Estilo do corpo do modal */
  .modal-body {
    padding: 20px;
    font-size: 16px;
    color: #333;
  }
  
  /* Estilo do rodapé do modal */
  .modal-footer {
    padding: 10px 15px;
    text-align: right;
  }
  
  /* Estilo dos botões do modal */
  .modal-footer .btn {
    padding: 10px 10px;
    font-size: 12px;
    border-radius: 5px;
    margin-left: 10px;
  }
  
  .modal-footer .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  .modal-footer .btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  /* Estilo para o botão de fechar quando o modal está visível */
  .modal-footer .btn-secondary:hover,
  .modal-footer .btn-danger:hover {
    background-color: #5a6268;
    border-color: #545b62;
  }
  
  .modal-footer .btn-danger:hover {
    background-color: #c82333;
    border-color: #bd2130;
  }
  