body {
  background-color: #ecf2f8;
}
@media (min-width: 991.98px) {
  main {
    padding-left: 210px;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 15px 0 0;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 210px;
  z-index: 600;
}

#main-navbar {
 
  background-color: #ecf2f8;}
  
@media (max-width: 991.98px) {
  .sidebar {
    width: 100%;
   /* z-index: 0;*/
  }
  .lg {
    margin-left: 45px;
  }
  #main-navbar {
    /*z-index: 1;*/
    background-color: #ecf2f8;
  }

}
.sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  /* overflow-x: hidden;
  overflow-y: auto; */
}

/*
.card {
  margin-top: 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: .25rem;
}

.card .card-header {
  background-color: #fff;
  border-bottom: none;
}

.leaflet-container {
  width: 75%;
  height: 100vh;
  margin: 0 auto;
  margin-bottom: 20px;
}

*/

.text-hover.list-group-item-action:hover,
.text-hover.list-group-item-action:hover *,
.text-hover.list-group-item-action:focus,
.text-hover.list-group-item-action:focus *,
.text-hover.list-group-item-action:active,
.text-hover.list-group-item-action:active * {
  color: #122b48 !important;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.info h6 {
  margin: 0 0 5px;
  color: #777;
}

.smollText {
  font-size: 14.3px;
}

.mediunText {
  font-size: 15px;
  color: #6080A3;
}

.labeFormMargin {
  margin-bottom: -1px;
  
}



